export const HeaderLinks = [
  {
    name: 'Affiliates',
    to: '#affiliates',
  },
  {
    name: 'Advertisers',
    to: '#advertisers',
  },
  {
    name: 'About VMG',
    to: '#about',
  },
  {
    name: 'FAQ',
    to: '#faq',
  },
  {
    name: 'Contact Us',
    to: '#contact',
  },
];
